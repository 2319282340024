@import "utils/theme.scss";

#editor{
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    .gjs-sm-sectors.gjs-one-bg.gjs-two-color{
        .hidden{
            display: none;
        }
    }

    .gjs-cv-canvas{
        left: auto;
        right: 0
    }
    .gjs-pn-commands{
        left: auto;
        right: 0;
    }
    .gjs-pn-views-container{
        left: 0;
        right: auto;
    }

    .gjs-pn-views{
        right: auto;
        left: 0;
        
        &>div{
            flex-direction: row-reverse;
        }
    }

    .gjs-pn-devices-c{
        right: 0;
    }
    .gjs-one-bg{
        background-color: $primary;
    }
    .gjs-three-bg{
        background-color: $primary-light;
    }

    .gjs-four-color{
        color: #109DF7;
        background-color: $primary-light;
    }

    .gjs-pn-options{
        left: calc(50% - 52px);
        right: auto;
        
        &>div{
            flex-direction: row-reverse;
        }
    }
    
    .gjs-pn-devices-c{
        
        &>div{
            flex-direction: row-reverse;
        }
    }
    .gjs-sm-sector{
        font-weight: 400;
        opacity: .9;
    }

    .gjs-block-categories{
        .gjs-title{
            font-size: 18px;
            background: $primary-light;
        }
    }
    .gjs-blocks-c{

        .gjs-block{
            width: 100%;
            min-height: 35px;
            max-height: 35px;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: flex-start;
            text-align: left;
            gap: 10px;
            flex-direction: row;
            padding: 10px 5px;
            margin: 5px 4px 0;

            &:before{
                width: 25px;
                font-size: 23px;
            }

            .gjs-block-label{
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                justify-content: flex-start;
                text-align: left;
                gap: 10px;
            }
    
            svg{
                height: 25px;
                width: 25px;
            }
            .gjs-block__media{
                margin: 0;
            }
        }
        .gjs-fonts:before{
            font-size: 25px;
            width: 25px;
        }
    }

    .gjs-category-title, .gjs-layer-title, .gjs-block-category .gjs-title, .gjs-sm-sector-title{
        font-weight: 500;
        letter-spacing: 0;
        transition: .3s;

        &:hover{
            background-color: $primary-light;
        }
    }
    .gjs-sm-sector-title{
        font-size: 18px;
        padding-left: 5px;
        padding-right: 5px;
        background: $primary-light;
    }

    .gjs-pn-btn:hover, .gjs-four-color-h:hover{
        color: #109DF7;
        background-color: $primary-light;
    }
    .gjs-toolbar{
        background-color: #F0803C80;
        
        .gjs-toolbar-item:hover{
            background-color: #F0803C80;
        }
    }
    .gjs-resizer-h{
        border-color: #F0803C80;
    }
    .gjs-cv-canvas .gjs-highlighter{
        outline-color: #f0803ccc;
    }
    .gjs-badge{
        background: #F0803C90;
    }
    .gjs-sm-properties{
        padding: 10px 0;
    }
    .hidden{
        display: none;
    }
}