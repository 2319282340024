 /*Theme colors*/
 $primary: #1e295c;
 $primary-light: #28356f;
 $primary-dim: #28356f88;
 $secondary: #00abb3;
 $secondary-light: #5ce0e6;
 $secondary-dim: #5ce0e670;


 @mixin generic-btn {
    display: inline-block;
    max-width: 150px;
    // width: 100%;
    padding: 15px;
    background: $secondary;
    color: #fff;
    font-size: 14px;
    border: 0px;
    border-radius: 7px;
    cursor: pointer;
    transition: .3s;

    &:hover{
        background-color: $secondary-dim;
    }
}