@import "utils/theme.scss";

.sidebar_container{
	height:  100vh;
	background: $primary;
	color:  #fff;
	position: relative;
	width: 100%;
	max-width: 300px;

	.sidebar_content{
		width: 100%;
		max-width: 300px;
		
		&.collapsed{
			display: none;
		}
	}

	.sidebar_nav{
		width: 100%;
		overflow-y: scroll;
		height: calc(100vh - 130px);

		&::-webkit-scrollbar {
		  width: 0px;
		}
	}

	.logo_container{
		display: flex;
		justify-content: center;
		padding: 10px;
		align-items: center;
		grid-gap: 10px;

		.logo_name{
			margin: 0px;

			span{
				color: $primary-dim;
			}
		}
		img{
			width: auto;
			height: 65px;
		}
	}

	.sidebar_pages_links{

		a{
			width: 100%;
			outline: none;
			display: block;
			color:  #ededed;
			font-size: 17px;
			text-decoration: none;
			border-bottom:  1px solid $primary-light;
			border-right: 4px solid transparent;
			transition: .3s all ease;
			padding: 15px 10px;
			
			&>div{
				display: flex;
				grid-gap: 10px;

				small{
					font-size: 10px;
				}

				&:first-child{
					margin-bottom: 5px;
				}
			}
			svg{
				height: 25px;
				width: 25px;
				
				&, path{
					color: #fff;
					stroke: #fff;
				}
			}
			&:hover, &.active{
				background: $secondary-dim;
				border-right-color: $secondary;
			}
		}
	}
}