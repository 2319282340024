@import "utils/theme.scss";

#settings-popup-container-main{

	z-index: 99;

	h3{
		color: #444;
	}

	&, .hiding_layout{
		position: fixed;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100vw;
		height: 100vh;
		top: 0;
		left: 0;
		background: #00000060;
		backdrop-filter: blur(1px);
	}

	.hiding_layout{
		position: absolute;
		z-index: -1;
	}

	.poopup_main_content{
		position: relative;
		width: 100%;
		height: fit-content;
		max-height: 80vh;
		max-width: 500px;
		background: #fff;
		margin: auto;
		border-radius: 10px;
		padding: 15px;
		overflow-y: auto;

		h3{
			color: #333;
			margin: 0px 0px 10px;
		}

		.close_cross_btn{
			padding: 0;
			background: transparent;
			border: 0;
			cursor: pointer;
			position: absolute;
			top: 0px;
			right: 0px;
			width: fit-content;

			svg{
				height: 20px;
				width: 20px;
				border-radius: 100%;
				background: transparent;
				fill: red;
			}
		}

		.settings_popup_inner{
			overflow: auto;
			height: 100%;
			color: #444;

			.pagePreloader{
				width: 100%;
				height: 250px;
			}


			h4{
				margin-top: 0px;
				color: #444;
			}
			.page_settings{
				display: grid;
				grid-template-columns: 1fr 1fr;

				
				&.page_tabs h4{
					margin-top: 0px;
					color: #444;
					padding-bottom: 5px;
					border-bottom: 3px solid #ddd;
					cursor: pointer;

					&.active{
						border-bottom-color: $secondary-light;
					}
					&:hover{
						border-bottom-color: $secondary-light;
					}
				}
			}

			.available_templates{
				display: grid;
				grid-template-columns: 1fr 1fr;
				grid-gap: 15px;
				padding: 10px 5px;

				.template_card{
					padding: 10px;
					box-shadow: 0px 0px 5px 0px $secondary-light;
					border-radius: 7px;
					display: flex;
					justify-content: space-between;
					grid-gap: 15px;

					&>div{
						min-width: 50px;
					}

					button{
						padding: 0;
						background-color: transparent;

						svg{
							color: $secondary-light;
							width: 20px;
							height: 20px;
							
							&:hover{
								color: $secondary;
							}
						}
					}
				}
			}

			.pageinfo_form{
				display: grid;
				// grid-template-columns: 1fr 1fr;
				grid-gap: 15px;
				align-items: end;

				input, label, select, textarea{
					width: 100%;
					color: #333;

					span{
						font-size: 16px;
					}
				}

				input, select, textarea{
					font-size: 15px;
					border: 1px solid #ccc;
					padding: 15px;
					height: 40px;
					border-radius: 5px;
				}
				select{
					background: #fff;
					padding: 0 15px;
				}
				textarea{
					height: 100px;
				}

				button{
					background: $secondary-light;
					border-radius: 7px;
					border: 0px solid transparent;
					padding: 15px;
					color: #fff;
					font-size: 16px;
					display: block;
					max-width: 150px;
					width: 100%;
					margin-left: auto;
					cursor: pointer;

					&:hover{
						background: $secondary;
					}

					&.delete_button{
						background-color: red;
						
						&:hover{
							background-color: rgb(188, 0, 0);
						}
					}
				}
				.page_form_btns{
					display: flex;
					justify-content: right;

					button{
						display: inline-block;
						margin-left: 20px;
					}
				}
			}

		}


	}


}