@import "utils/theme.scss";

#hks_custom_menu{
	position: absolute;
    z-index: 5;
    left: calc(15% + 5px);
	width: 280px;
    padding: 5px;
	color: #fff;
	height: 40px;
	display: flex;
	align-items: center;
	user-select: none;

	#pages_hider{
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: transparent;
	}

	.hks_save_btn{
		margin-left: 5px;
		margin-right: 0px;
		font-size: 18px;
	}

	.selected_page{
		width: 100%;
		border: 1px solid #999;
		padding: 5px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		cursor: pointer;
	}
	
	.all_pages{
		background: #1e295c;
		max-width: 300px;
		position: absolute;
		top: 35px;
		width: 100%;
		max-height: calc(100vh - 100px);
		overflow: auto;
		
		.page_entry{
			cursor: pointer;
			display: block;
			padding: 7px 10px;
			transition: .3s;
			border-bottom: 1px solid #888;
			line-break: anywhere;
			position: relative;
			padding-right: 30px;

			&.selected_page{
				background-color: #9d542766;
			}

			.edit_page{
				position: absolute;
				top: 10px;
				right: 8px;

				svg:hover{
					color: $secondary-light;
				}
			}

			&:hover{
				background: #888; 
			}
		}
		
	}
}