@import "utils/theme.scss";

.delete_project{
	.delete_button{
		max-width: 100%;
		width: 100%;
		background: rgb(255, 32, 32);
		
		&:hover{
			background: red;
		}
		&[disabled]{
			background: rgb(255, 117, 117);
		}
	}

	label input{
		width: 100%;
		height: 50px;
		margin-bottom: 20px;
		padding: 10px;
		border-radius: 7px;
		border: 1px solid #ccc;
		font-size: 17px;
	}
}